import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 자사 알뜰폰 플랫폼인 ‘핀다이렉트샵’ 카카오톡 채널에 고객이 상담 대기시간 파악가능한 신호등 체계 도입",
  "| 실시간 상담시간 이후에도 상담 미리 요청할 수 있도록 카카오톡 채널 내 질문 남기기 서비스 오픈",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/29/29_img1.png" style={{ maxWidth: "300px", margin: "auto" }} alt="Figure2" />
      </div>
      <div className="desc">&lt;알뜰폰 플랫폼 ‘핀다이렉트샵’의 카카오톡 기반 상담 서비스 &gt;</div>
    </div>
    <p>
      <br />
      <br />
      비대면 통신가입 플랫폼 ‘핀다이렉트샵’을 운영하고 있는 스테이지파이브(대표 서상원)가 3월 21일(월)부터 자사 알뜰폰 고객을 위한
      카카오톡 기반 상담을 업그레이드한다고 밝혔다.
      <br />
      <br />
      기존 알뜰폰 시장에서 ‘돌아오지 않는 메아리’와 같은 상담 관련 고객불편은 지속적으로 제기되어 온 바 있다. 이에
      스테이지파이브는 소비자 접근성이 놓은 카카오톡 상담을 중심으로 알뜰폰에 대한 소비자 경험을 긍정적으로 바꾼다는 전략이다.
      <br />
      <br />
      해당 社는 기존에도 카카오톡 기반 통신가입을 업계 최초 도입하여 규제샌드박스를 통과한바 있다. 또한, 스테이지파이브는
      대기업계열 MVNO사 중에서는 유일하게 카카오톡 기반 상담을 제공하고 있다. 상담을 위한 별도의 앱 설치나 사이트 방문이 필요 없는
      셈이다.
      <br />
      <br />
      이번 업그레이드에서는 알뜰폰 고객들의 CS관련 페인 포인트를 우선적으로 해소하는데 집중했다. ▲ 상담톡 내 신호등체계 신설 ▲
      실시간 상담시간 외 상담 先등록 할 수 있는 창구 마련이 핵심이다. 이를 통해 고객들과 인터랙티브한 소통이 가능하도록 설계한
      것이다.
      <br />
      <br />
      스테이지파이브 관계자는 “기존 도입하고 있었던 실시간 카카오톡 상담서비스를 고객관점에서 바라보고 지속적으로 발전시키는
      방향으로 나아갈 것”이라고 밝혔다.
      <br />
    </p>
  </div>
)

const press27 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴CR실"
      writer="커뮤니케이션팀 pr@stagefive.com"
      date="2022년 3월 21일 (*엠바고, 3월 22일 조간 보도 요청) "
      // pageInfo="*총 2매 / 첨부사진 2매"
      title="알뜰폰 상담도 카카오톡으로! 스테이지파이브, 고객불편 해소 위해 카카오톡 상담채널 업그레이드"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press27
